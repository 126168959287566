/*
 * @Author: Zheng Lei
 * @Email: baimoc@163.com
 * @Date: 2023-12-01 14:20:58
 * @LastEditTime: 2024-12-10 23:39:54
 * @FilePath: \rna-browser\src\router\index.tsx
 */
import React, { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { nanoid } from 'nanoid'
import type { RouteObject } from 'react-router-dom'

const Home = lazy(() => import('../views/home'))
const Browser = lazy(() => import('../views/browser'))
const Tools = lazy(() => import('../views/tools'))
const Download = lazy(() => import('../views/download'))
const Help = lazy(() => import('../views/help'))
const Contact = lazy(() => import('../views/contact'))
const Main = lazy(() => import('../views/main'))
const Test = lazy(() => import('../views/test'))
const Interaction = lazy(() => import('../views/main/c-views/interaction'))
const TranscriptBrowser = lazy(() => import('../views/main/c-views/transcript'))
const TranscriptomeBrowser = lazy(() => import('../views/main/c-views/transcriptome'))
const RNA2DStructure = lazy(() => import('../views/main/c-views/rna-2d-structure'))
const RNA3DStructure = lazy(() => import('../views/main/c-views/rna_3d_structure'))
const Protein3DStructure = lazy(() => import('../views/main/c-views/protein_3d_structure'))
const Genome3D = lazy(() => import('../views/main/c-views/genome_3d'))
const DataHub = lazy(() => import('../views/main/c-views/data_hub'))
const DataIndex = lazy(() => import('../views/main/c-views/data_index'))
const RNAExpression = lazy(() => import('../views/main/c-views/rna_expression'))
const RNASequence = lazy(() => import('../views/main/c-views/rna_sequence'))
const RNABrowser = lazy(() => import('../views/main/c-views/rna_browser'))
const RNACorrelation = lazy(() => import('../views/main/c-views/rna_correlation'))

type BmRouteObject = RouteObject & {
  key: string
  title: string
}

const router: BmRouteObject[] = [
  {
    key: nanoid(),
    title: 'Home',
    path: '/',
    element: <Navigate to='/home' />
  },
  {
    key: nanoid(),
    title: 'Home',
    path: '/home',
    element: <Home />
  },
  {
    key: nanoid(),
    title: 'RNABrowser',
    path: '/rna_browser',
    element: <RNABrowser />
  },
  {
    key: nanoid(),
    title: 'Transcriptome',
    path: '/transcriptome',
    element: <TranscriptomeBrowser />
  },
  {
    key: nanoid(),
    title: 'RNA Sequence',
    path: '/rna_sequence',
    element: <RNASequence />
  },
  {
    key: nanoid(),
    title: 'Transcript',
    path: '/transcript',
    element: <TranscriptBrowser />
  },
  {
    key: nanoid(),
    title: 'Interaction',
    path: '/interaction',
    element: <Interaction />
  },
  {
    key: nanoid(),
    title: 'RNA2DStructure',
    path: '/rna2dstructure',
    element: <RNA2DStructure />
  },
  {
    key: nanoid(),
    title: 'RNA3DStructure',
    path: '/rna3dstructure',
    element: <RNA3DStructure />
  },
  {
    key: nanoid(),
    title: 'Protein3DStructure',
    path: '/protein3dsturcture',
    element: <Protein3DStructure />
  },
  {
    key: nanoid(),
    title: 'RNA Expression',
    path: '/rnaexpression',
    element: <RNAExpression />
  },
  {
    key: nanoid(),
    title: 'RNA Correlation',
    path: '/rna_correlation',
    element: <RNACorrelation />
  },
  {
    key: nanoid(),
    title: '3D Genome',
    path: '/3dgenome',
    element: <Genome3D />
  },
  {
    key: nanoid(),
    title: 'Browser',
    path: '/browser',
    element: <Browser />
  },
  {
    key: nanoid(),
    title: 'Tools',
    path: '/',
    element: <Tools />
  },
  {
    key: nanoid(),
    title: 'Download',
    path: '/download',
    element: <Download />
  },
  {
    key: nanoid(),
    title: 'Help',
    path: '/help',
    element: <Help />
  },
  {
    key: nanoid(),
    title: 'Contact',
    path: '/contact',
    element: <Contact />
  },
  {
    key: nanoid(),
    title: 'Main',
    path: '/main',
    element: <Main />
  },
  {
    key: nanoid(),
    title: 'Data Hub',
    path: '/data_hub',
    element: <DataHub />
  },
  {
    key: nanoid(),
    title: 'Data Index',
    path: '/data_index',
    element: <DataIndex />
  },
  {
    key: nanoid(),
    title: 'Test',
    path: '/test',
    element: <Test />
  }
]

export default router
